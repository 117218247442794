.card-for-catalog-container {
  width: 400px;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  text-decoration: none;
}


.card-for-catalog-text {
  color: rgba(255, 255, 255, 0);
  width: 100%;
  height: 100%;
  font-size: 48px;
  text-align: center;
  transition: 0.3s;
  padding-top: 50%;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0%;
}
.card-for-catalog-text:hover {
  color: var(--dl-color-gray-white);
  background-color: rgba(0, 0, 0, 0.7);
}















.card-for-catalog-root-class-name15 {
  background-size: cover;
  background-image: ad99ec70-80eb-4d48-865f-78c476719407;
}
.card-for-catalog-root-class-name16 {
  background-size: cover;
  background-image: 3c1c65a9-8c5b-482e-96ed-47e805ec17f7;
}
.card-for-catalog-root-class-name17 {
  background-size: cover;
  background-image: 85fc89d2-fa57-4720-bdf5-4ed528417225;
}
.card-for-catalog-root-class-name18 {
  background-size: cover;
  background-image: 3c1c65a9-8c5b-482e-96ed-47e805ec17f7;
}
.card-for-catalog-root-class-name19 {
  background-size: cover;
  background-image: ad99ec70-80eb-4d48-865f-78c476719407;
}
.card-for-catalog-root-class-name20 {
  background-size: cover;
  background-image: 85fc89d2-fa57-4720-bdf5-4ed528417225;
}







.card-for-catalog-root-class-name28 {
  background-size: cover;
  background-image: url("/mosaic/%C3%B0%C2%B0%C3%B0%C2%BB%C3%B0%C2%B5%C3%B1%C2%88%C3%B0%C2%B0-200h.jpg");
}
.card-for-catalog-root-class-name29 {
  background-size: cover;
  background-image: url("/mosaic/%C3%B0%C2%98%C3%B0%C2%BB%C3%B1%C2%8C%C3%B1%C2%8F-200h.jpg");
}
.card-for-catalog-root-class-name30 {
  background-size: cover;
  background-image: url("/mosaic/mop12-200h.jpg");
}
.card-for-catalog-root-class-name31 {
  background-size: cover;
  background-image: url("/mosaic/%C3%B0%C2%A0%C3%B0%C2%B8%C3%B1%C2%81%C3%B1%C2%83%C3%B0%C2%BD%C3%B0%C2%BE%C3%B0%C2%BA-200h.jpg");
}



















@media(max-width: 1600px) {
  .card-for-catalog-container {
    width: 340px;
    height: 340px;
  }
  .card-for-catalog-text {
    font-size: 40px;
    padding-top: 150px;
  }
}
@media(max-width: 1200px) {
  .card-for-catalog-container {
    width: 400px;
    height: 400px;
  }
  .card-for-catalog-text {
    font-size: 48px;
    padding-top: 201px;
  }
}
@media(max-width: 991px) {
  .card-for-catalog-container {
    width: 350px;
    height: 350px;
  }
  .card-for-catalog-text {
    left: 0px;
    color: var(--dl-color-gray-white);
    bottom: 0px;
    height: 20%;
    position: absolute;
    font-size: 40px;
    padding-top: var(--dl-space-space-unit);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
@media(max-width: 767px) {
  .card-for-catalog-container {
    width: 200px;
    height: 200px;
  }
  .card-for-catalog-text {
    font-size: 1.5rem;
    padding-top: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 479px) {
  .card-for-catalog-container {
    width: 200px;
    height: 200px;
  }
  .card-for-catalog-root-class-name27 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name28 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name29 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name30 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name31 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name32 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name33 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name34 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name35 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name36 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name37 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name38 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name39 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name40 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name41 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name42 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name43 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name44 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name45 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name46 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name47 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name48 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name49 {
    width: 150px;
    height: 150px;
  }
  .card-for-catalog-root-class-name50 {
    width: 150px;
    height: 150px;
  }
}
