.news-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-100);
}
.news-navbar-interactive {
  width: 100%;
  height: 90px;
  display: flex;
  z-index: 100;
  position: relative;
  box-shadow: 5px 5px 10px 15px #212121;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: var(--dl-color-gray-100);
}
.news-link {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.news-desktop-menu {
  flex: 1;
  display: none;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}
.news-link1 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  align-self: center;
  text-decoration: none;
}
.news-burger-menu {
  display: flex;
}
.news-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.news-header {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  background-color: var(--dl-color-gray-100);
}
.news-text04 {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  right: 0px;
  bottom: 0px;
  margin: auto;
  position: relative;
  font-size: 82px;
  align-self: center;
  text-align: center;
}
.news-main-photo {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  position: relative;
  align-items: flex-start;
  background-color: var(--dl-color-gray-100);
}
.news-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 80%;
  bottom: 0px;
  height: 750px;
  margin: auto;
  position: relative;
  object-fit: cover;
}
.news-text05 {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  position: relative;
  align-self: center;
  max-height: auto;
  min-height: auto;
  align-items: flex-start;
  background-color: var(--dl-color-gray-100);
}
.news-text06 {
  color: var(--dl-color-gray-white);
  width: 90%;
  margin: auto;
  font-size: 25px;
}
.news-add-photos {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}
.news-image1 {
  width: 700px;
  height: 700px;
  position: relative;
  object-fit: cover;
}
.news-image2 {
  width: 700px;
  height: 700px;
  object-fit: cover;
}
.news-image3 {
  width: 700px;
  height: 700px;
  object-fit: cover;
}
.news-image4 {
  width: 700px;
  height: 700px;
  object-fit: cover;
}
.news-footer {
  width: 100%;
  height: auto;
  display: block;
  z-index: 100;
  position: relative;
  margin-top: 10%;
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: center;
  background-color: #212121;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.news-footer1 {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 170px;
  margin: auto;
  display: flex;
  position: absolute;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-end;
}
.news-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: #ffffff;
  border-style: outset;
  border-width: 4px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.news-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.news-text15 {
  color: rgb(255, 255, 255);
}
@media(max-width: 1920px) {
  .news-desktop-menu {
    display: none;
  }
  .news-burger-menu {
    display: flex;
  }
}
@media(max-width: 1600px) {
  .news-navbar-interactive {
    height: 72px;
  }
  .news-header {
    height: 244px;
  }
  .news-main-photo {
    height: auto;
  }
  .news-image {
    width: 80%;
    height: 650px;
  }
  .news-text05 {
    height: auto;
  }
  .news-image1 {
    width: 530px;
    height: 530px;
  }
  .news-image2 {
    width: 530px;
    height: 530px;
  }
  .news-image3 {
    width: 530px;
    height: 530px;
  }
  .news-image4 {
    width: 530px;
    height: 530px;
  }
  .news-footer1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
@media(max-width: 1200px) {
  .news-container {
    background-color: var(--dl-color-gray-100);
  }
  .news-header {
    background-color: var(--dl-color-gray-100);
  }
  .news-main-photo {
    height: auto;
    background-color: var(--dl-color-gray-100);
  }
  .news-image {
    height: 500px;
  }
  .news-text05 {
    height: auto;
    background-color: var(--dl-color-gray-100);
  }
  .news-image1 {
    width: 500px;
    height: 500px;
  }
  .news-image2 {
    width: 500px;
    height: 500px;
  }
  .news-image3 {
    width: 500px;
    height: 500px;
  }
  .news-image4 {
    width: 500px;
    height: 500px;
  }
  .news-footer1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
@media(max-width: 991px) {
  .news-main-photo {
    height: auto;
  }
  .news-image {
    height: 400px;
  }
  .news-text05 {
    height: auto;
  }
  .news-text06 {
    font-size: 18px;
  }
  .news-image1 {
    width: 650px;
    height: 650px;
  }
  .news-image2 {
    width: 650px;
    height: 650px;
  }
  .news-image3 {
    width: 650px;
    height: 650px;
  }
  .news-image4 {
    width: 650px;
    height: 650px;
  }
  .news-footer1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
@media(max-width: 767px) {
  .news-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .news-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .news-mobile-menu {
    background-color: var(--dl-color-gray-100);
  }
  .news-text04 {
    color: var(--dl-color-gray-white);
    font-size: 64px;
  }
  .news-main-photo {
    height: auto;
  }
  .news-image {
    height: 350px;
  }
  .news-text05 {
    height: auto;
  }
  .news-footer1 {
    left: 0px;
    right: 0px;
    margin: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .news-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .news-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .news-text15 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .news-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .news-mobile-menu {
    padding: 16px;
    background-color: var(--dl-color-gray-100);
  }
  .news-main-photo {
    height: auto;
  }
  .news-image {
    width: 100%;
    height: 300px;
  }
  .news-text05 {
    height: auto;
    padding: var(--dl-space-space-twounits);
  }
  .news-text06 {
    color: var(--dl-color-gray-white);
    font-size: 25px;
  }
  .news-image1 {
    width: 450px;
    height: 450px;
  }
  .news-image2 {
    width: 450px;
    height: 450px;
  }
  .news-image3 {
    width: 450px;
    height: 450px;
  }
  .news-image4 {
    width: 450px;
    height: 450px;
  }
  .news-footer {
    height: 625px;
  }
  .news-footer1 {
    left: 0px;
    right: 0px;
    height: 146px;
    margin: auto;
    padding: var(--dl-space-space-unit);
  }
  .news-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .news-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .news-text15 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
