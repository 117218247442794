.catalog-v22-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #D5D5D5;
}
.catalog-v22-navbar {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.catalog-v22-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 1%;
  align-items: flex-start;
}
.catalog-v22-text {
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  font-size: 82px;
  font-style: normal;
  text-align: left;
  padding-left: 10%;
  font-family: "Montserrat";
  font-weight: 500;
}
.catalog-v22-container2 {
  gap: var(--dl-space-space-sixunits);
  width: 95%;
  height: auto;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.catalog-v22-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-medium);
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: 2.5%;
}
.catalog-v22-container4 {
  flex: 0 0 auto;
  right: 2.5%;
  width: 100px;
  bottom: -4px;
  height: 100px;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.catalog-v22-button {
  width: 100%;
  height: 100%;
  transition: 0.3s;
  border-color: #D5D5D5;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgba(255, 255, 255, 0);
}
.catalog-v22-button:hover {
  box-shadow: 0px 0px 10px 15px rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.5);
}
.catalog-v22-text3 {
  font-size: 36px;
}
.catalog-v22-container5 {
  top: 0px;
  flex: 0 0 auto;
  left: 2.5%;
  width: 100px;
  bottom: 0px;
  height: 100px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.catalog-v22-button1 {
  width: 100%;
  height: 100%;
  transition: 0.3s;
  border-color: #D5D5D5;
  border-radius: var(--dl-radius-radius-round);
  background-color: rgba(255, 255, 255, 0);
}
.catalog-v22-button1:hover {
  box-shadow: 0px 0px 10px 15px rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0.5);
}
.catalog-v22-text4 {
  font-size: 36px;
}
.catalog-v22-container6 {
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 30%;
  bottom: -4px;
  height: 80%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
}
.catalog-v22-footer {
  width: 100%;
  height: auto;
  display: block;
  z-index: 100;
  position: relative;
  margin-top: 10%;
  align-items: flex-start;
  border-radius: 0px;
  flex-direction: column;
  justify-content: center;
  background-color: #d5d5d5;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.catalog-v22-footer1 {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 170px;
  margin: auto;
  display: flex;
  position: absolute;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-end;
}
.catalog-v22-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: #212121;
  border-style: outset;
  border-width: 4px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.catalog-v22-container7 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.catalog-v22-text5 {
  color: #212121;
}
@media(max-width: 1600px) {
  .catalog-v22-footer1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
@media(max-width: 1200px) {
  .catalog-v22-footer1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
@media(max-width: 991px) {
  .catalog-v22-container2 {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .catalog-v22-container3 {
    margin-bottom: 4%;
  }
  .catalog-v22-footer1 {
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
@media(max-width: 767px) {
  .catalog-v22-container2 {
    gap: var(--dl-space-space-sixunits);
  }
  .catalog-v22-container3 {
    margin-bottom: 12%;
  }
  .catalog-v22-footer1 {
    left: 0px;
    right: 0px;
    margin: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .catalog-v22-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .catalog-v22-container7 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .catalog-v22-text5 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {

	.catalog-v22-text {
	font-size:60px;
	text-align: center;
	
	}
  .catalog-v22-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .catalog-v22-footer {
    height: 200px;
    margin-top: 0px;
  }
  .catalog-v22-footer1 {
    left: 0px;
    right: 0px;
    height: 146px;
    margin: auto;
    padding: var(--dl-space-space-unit);
  }
  .catalog-v22-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .catalog-v22-container7 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .catalog-v22-text5 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
